export const convertAll = {
  methods: {
    convertDate(tmpdate) {
      if (tmpdate) {
        if (tmpdate.length <= 10) {
          var monarray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
          var date = tmpdate.substring(8, 10)
          var year = tmpdate.substring(0, 4)
          var month = parseInt(tmpdate.substring(5, 7))
          if (month <= 12) {
            var newmonth = monarray[month - 1]
            var newdate = date + "-" + newmonth + "-" + year
            return newdate
          } else {
            return tmpdate
          }
        } else {
          return tmpdate
        }
      } else {
        return tmpdate
      }
    }, //end of convertDate
    convertmobile(tmpmobilenumber) {
      if (tmpmobilenumber) {
        var newmobilenumber = tmpmobilenumber.substring(0, 5) + " " + tmpmobilenumber.substring(5, 10)
        return newmobilenumber
      } else {
        return tmpmobilenumber
      }
    }, // end of convertmobile
    getitemperpage(userconfig) {
      var defaultItemsPerPage = 10
      if (userconfig) {
        for (var i = 0; i < userconfig.length; i++) {
          if (userconfig[i].description == "Items Per Page") return parseInt(userconfig[i].value)
        }
        return defaultItemsPerPage
      } else {
        return defaultItemsPerPage
      }
    }, //end of getitemperpage
  }, // end of methods
}
