<!-- V1.0 base version updated by Raja Mohamed on 19-Oct-20
-->
<template>
  <v-container>
     <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <div>
      <v-row v-if="leaveOptionsflag" class="flex" centre>
      
        <v-toolbar color="#16283F" dark flat>
          <v-tabs align="left" fixed-tabs>
            <v-tab
              v-for="leaveOption in leaveOptions"
              :key="leaveOption"
              tile
              text
              color="secondary"
              @click="setleave(leaveOption)"
              >{{ leaveOption }}
            </v-tab>
            <v-tab>
              <!-- menu for list of all leave year -->
              <v-menu v-if="holidayyears.length" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
                    Holidays
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>

                <v-list class="grey lighten-3">
                  <v-list-item v-for="item in holidayyears" :key="item" @click="showholidays(item)">
                    {{ item }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-row>
    </div>

    <v-row v-if="dashboard">
      <!-- To display the leave summary for given date by default it will be today's date.-->
      <v-col cols="12" xs="3" sm="3" md="3" lg="3">
           <DatePicker
                          :menu="menuld"
                          :datevalue="picker"
                          :datelabeltext="'Date'"
                          @dateassinged="editedItem.picker = $event ,getleavedetails(convertDate(picker))"
                        />
    
      </v-col>
      <v-col cols="12" xs="9" sm="9" md="9" lg="9">
        <table class="leavesummary">
          <tr class="grey">
            <th>EL</th>
            <th>WFH</th>
            <th>Onsite</th>
            <th>Travel</th>
          </tr>
          <tr align="center">
            <td class="center">
              <h3>{{ EarnedLeave }}</h3>
            </td>
            <td>
              <h3>{{ WFH }}</h3>
            </td>
            <td>
              <h3>{{ Onsite }}</h3>
            </td>
            <td>
              <h3>{{ Travel }}</h3>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
 
    <event v-if="dashboard" :eventlist="leavecalenders"></event>

    <v-data-table
      v-if="!dashboard"
      :headers="getheaders"
      :items="getdataarray"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="name"
      item-key="uid"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :show-expand="!summaryflag"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="!dashboard">Leaves </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!dashboard" color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD leave</v-btn
              ><!-- add button to add leave-->
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                
                      <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                    <v-row v-if="summary">
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <h4>Year</h4>
                        <div>{{ editedItem.year }}</div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <h4>Opening Balance</h4>
                        <div>{{ editedItem.openingbalance }}</div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <h4>Credited Balance</h4>
                        <div>{{ editedItem.creditedbalance }}</div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <h4>Leaves Taken</h4>
                        <div>{{ editedItem.leavetaken }}</div>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-text-field
                          v-model="editedItem.encash"
                          :rules="numberinput"
                          label="Carry Forward"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-else>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-select
                          v-model="editedItem.type"
                          label="Type"
                          :rules="inputrequired"
                          :items="leavetypes"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <DatePicker
                          :menu="menusd"
                          :datevalue="editedItem.startdate"
                          :datelabeltext="'Start Date'"
                          @dateassinged="editedItem.startdate = $event"
                        />
                     
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-select
                          v-model="editedItem.starttime"
                          label="Start Time"
                          :rules="inputrequired"
                          :items="leavetimes"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <DatePicker    :menu="menued"
                          :datevalue="editedItem.enddate"
                          :datelabeltext="'Start Date'"
                          @dateassinged="editedItem.enddate = $event"
                        />
                     
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-select
                          v-model="editedItem.endtime"
                          label="End Time"
                          :rules="inputrequired"
                          :items="leavetimes"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-text-field
                          v-model="editedItem.reason"
                          :rules="inputrequired"
                          label="Reason"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" text :disabled="!valid" @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-if="!summaryflag" v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="6" sm="6" md="6" lg="6">
              <div>
                <h4>Reason</h4>
                {{ item.reason }}
              </div>
            </v-col>
          </v-row>
        </td>
      </template>

       <template v-slot:[`item.appliedon`]="{ item }">
        {{ convertDate(item.appliedon) }}
      </template>

      <template v-slot:[`item.approvedon`]="{ item }">
        {{ convertDate(item.approvedon) }}
      </template>

      <template v-slot:[`item.enddate`]="{ item }">
        {{ convertDate(item.enddate) }}
      </template>

      <template v-slot:[`item.startdate`]="{ item }">
        {{ convertDate(item.startdate) }}
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ convertDate(item.date) }}
      </template>

      

      <template v-if="approval" v-slot:[`item.approval`]="{ item }">
        <v-icon small class="mr-2" @click="approveleave(item)"> thumb_up </v-icon>
        <v-icon small @click="rejectleave(item)"> thumb_down </v-icon>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.editing" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!--<v-icon  v-if="item.editing" small @click="deleteItem(item)" > mdi-delete </v-icon>-->
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add leave</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { leavesummariesRef, leavedetailsRef, configgenRef, holidaysRef, empRef } from "../../fb"
import { formMixin } from "@/mixins/formMixin.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import event from "@/components/calendar/events"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import DatePicker from "@/components/common/Datepicker.vue"
import Spinner from "@/components/common/spinner.vue"
import {mapState} from "vuex"
export default {
  name: "Leaves",
  components: {
    event,Snackbar,Alertmsg,DatePicker,Spinner
  },
  // props: ["roles"],
  mixins: [formMixin, emailMixin, convertAll], // for confirmation while delete

  data: () => ({
    dialog: false,
    valid: true,
    lazy: false,
    menusd: false,
    menued: false,
    menuld: false,
    search: "", // for data table search
    domain: "",
    notloaded: true, // will be made false once the data-table items fetched from db. till that time loading please wait message will be shown to the user
    singleExpand: false,
    expanded: [],
    expandflag: true,
    //headersmgrhr,headersself,headers
    headers: [],

    headersself: [
      // data table headers
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },
      { text: "Opening Balance", value: "openingbalance" },
      { text: "Credited Balance", value: "creditedbalance" },
      { text: "Leaves Taken", value: "leavetaken" },
      { text: "Carry Forward", value: "encash" },
      { text: "Closing Balance", value: "closingbalance" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersdeatailed: [
      {
        text: "Type",
        align: "start",
        sortable: true,
        value: "type",
      },
      { text: "Start Date", value: "startdate" },
      { text: "End Date", value: "enddate" },
      { text: "Days", value: "days" },
      { text: "Status", value: "status" },
    ],
    domainheader: [
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },

      { text: "EMPID", value: "empid" },
      { text: "Name", value: "name" },
      { text: "Opening Balance", value: "openingbalance" },
      { text: "Credited Balance", value: "creditedbalance" },
      { text: "Leaves Taken", value: "leavetaken" },
      { text: "Carry Forward", value: "encash" },
      { text: "Closing Balance", value: "closingbalance" },
      //{ text: 'Actions', value: 'actions', sortable: false },
    ],

    approvalheader: [
      {
        text: "Type",
        align: "start",
        sortable: true,
        value: "type",
      },
      { text: "EMPID", value: "empid" },
      { text: "Name", value: "name" },
      { text: "type", value: "type" },
      { text: "Start Date", value: "startdate" },
      { text: "End Date", value: "enddate" },
      { text: "Days", value: "days" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "approval", sortable: false },
    ],
    teamheader: [
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },

      { text: "EMPID", value: "empid" },
      { text: "Name", value: "name" },
      { text: "Opening Balance", value: "openingbalance" },
      { text: "Credited Balance", value: "creditedbalance" },
      { text: "Leaves Taken", value: "leavetaken" },
      { text: "Carry Forward", value: "encash" },
      { text: "Closing Balance", value: "closingbalance" },
      //{ text: 'Actions', value: 'actions', sortable: false },
    ],

    headersholiday: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      { text: "Day", value: "day" },
      { text: "Remarks", value: "remarks" },
      { text: "Type", value: "type" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    headersdashboard: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      { text: "WFH", value: "WFH" },
      { text: "Earned Leave", value: "EarnedLeave" },
      { text: "Onsite", value: "Onsite" },
      { text: "Travel", value: "Travel" },
    ],
    headersmgrhr: [
      {
        text: "Year",
        align: "start",
        sortable: true,
        value: "year",
      },
      { text: "Name", value: "name" },
      { text: "EmpID", value: "empid" },
      { text: "Opening Balance", value: "openingbalance" },
      { text: "Credited Balance", value: "creditedbalance" },
      { text: "Leaves Taken", value: "leavetaken" },
      { text: "Carry Forward", value: "encash" },
      { text: "Closing Balance", value: "closingbalance" },
      { text: "Actions", value: "actions", sortable: false },
     
    ],
   
    inputrequired: [(v) => !!v || "Input is required"],
    numberinput: [(v) => !!v || "Input is required", (v) => /^[0-9\s]+$/.test(v) || "Only Numbers  allowed"],

    leave: [], // leave array
    leaveTeam: [],
    leaveHR: [],
    leaveall: [],
    domainleave: [],
    leaveapporval: [],
    teamleave: [],
    todaysleave: [], // for dashboard
    dashboardleaves: [], //for dashboard
    leaveOptionsflag: false,
    Showleave: [],

    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      empid: "",
      year: "",
      startdate: "",
      enddate: "",
      starttime: "",
      endtime: "",
      reason: "",
      type: "",
      days: "",
      domain: "",
      appliedon: "",
      appliedby: "",
      approvedby: "",
      approvedon: "",
      mgrname: "",
      mgremail: "",
    },
    defaultItem: {
      name: "",
      email: "",
      empid: "",
      year: "",
      startdate: "",
      enddate: "",
      starttime: "",
      endtime: "",
      reason: "",
      type: "",
      days: "",
      domain: "",
      appliedon: "",
      appliedby: "",
      approvedby: "",
      approvedon: "",
      mgrname: "",
      mgremail: "",
    },
    leavetimes: ["Full day", "First session", "Second session"],
    leavestatus: ["Applied", "Apprved", "Rejected"],
    leavetypes: ["Earned Leave", "Onsite", "official Travel", "Work from Home"],

    leaveOptions: [],
    holidayslist: [],
    holidayyears: [],
    holidaysoption: false,
    summaryflag: true,
    leaveyear: "",
    leavecalenders: [], // for calendar leave array
    dashboard: false,

    editflag: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    snackbar: false,
    spinner:false,
    email: "",
    empid: "",
    name: "",
    managername: "",
    manageremail: "",
    self: true,
    team: false,
    hr: false,
    approval: false,
    summary: false,
    monitor: false,

    currentyear: new Date().toISOString().substring(0, 4),
    todaysdate: new Date().toISOString().substring(0, 10),
    excludingweekends: false,
    excludingholidays: false,
    allwoleavecarryforward: false,
    CFmaxdays: 0,
    leavespermonth: 0,
    loop: 0,

    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },
    decimalinput: [(v) => !!v || "Input is required", (v) => /^[0-9,.,,\s]+$/.test(v) || "Only Numbers and . allowed"],

    EarnedLeave: 0,
    WFH: 0,
    Onsite: 0,
    Travel: 0,
    picker: "",
    textpicker: "",
    roles: "",
    leaveOption:'Summary'
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Apply Leave" : "Edit Leave"
    },
   

     getheaders(){
      var datatableheader =[];
       switch(this.leaveOption){
         case 'Summary' : datatableheader=this.headersself;break;
         case 'Detailed' : datatableheader=this.headersdeatailed;break;
         case 'Holidays' : datatableheader=this.headersholiday;break;
         case 'Team' : datatableheader=this.teamheader;break;
         case 'Approval' : datatableheader=this.approvalheader;break;
         case 'All' : datatableheader=this.domainheader;break;
         case 'Dashboard' : datatableheader=this.headersdashboard;break;

       }
       return datatableheader;
    },

    getdataarray(){
      var datatablearray =[];
      console.log("inside getdataarray",this.leaveOption,this.leaveyear)
       switch(this.leaveOption){
         case 'Summary' : datatablearray=this.leave;break;
         case 'Detailed' : datatablearray=this.leaveall;break;
         case 'Holidays' : console.log("inside getdataarray",this.leaveyear)
                            datatablearray=this.holidayslist.filter(holidaylist => {
                             return holidaylist.year == this.leaveyear});break;
         case 'Team' : datatablearray=this.teamleave;break;
         case 'Approval' : datatablearray=this.leaveapporval;break;
         case 'All' : datatablearray=this.domainleave;break;
         case 'Dashboard' : datatablearray=this.dashboardleaves;break;

       }
       return datatablearray;
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain,
      currentemail: state=> state.userProfile.email
    })
	
    /*var filteredItems = this.holidayslist.filter((holidaylist) => {
        return holidaylist.year == this.leaveyear
      })*/
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    leaveyear:function(){
      this.leaveOption="Holidays"
    }
  },

  async created() {
    this.roles = await this.$store.state.userProfile
    if (this.roles) {
      if (this.roles.role.hradmin || this.roles.role.employee || this.roles.role.readuser) {
        if (this.roles.role.employee) {
          this.editflag = true
        }
        if (this.roles.role.hradmin) {
          this.hr = true
        }

        this.domain = this.roles.domain
        this.email = this.roles.email
        this.empid = this.roles.EmpID
        this.name = this.roles.name

        this.initialize()
        this.leaveOptions = []
        this.leaveOptions.push("Summary")
        this.leaveOptions.push("Detailed")
        // this.leaveOptions.push("Holidays");
        this.leaveOptionsflag = true

        this.Showleave = this.leave
        this.self = true
        this.headers = this.headersself
      } else {
        alert("Not authorized to access this page , kindly contact support")
        //this.$router.replace({ name: this.roles.menufrom})
        this.$router.push({
          name: this.roles.menufrom,
          params: { roles: this.roles },
        })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },


  mounted() {
    leavedetailsRef
      .where("domain", "==", this.currentDomain)
      .where("mgremail", "==", this.currentemail)
      .where("status", "==", "Applied")
      .orderBy("appliedon", "desc")
      .onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if(!this.notloaded)
          if (change.type === "added") {
            console.log("New leave added: ", change.doc.data())
            var editing = true
            this.leaveapporvalpush(change.doc, editing)
          }
        })
      }) //end of approval array

    configgenRef.where("domain", "==", this.domain).onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if ((change.type === "added" || change.type === "modified" || change.type === "removed") && (!this.notloaded)){
          this.getconfigs()
        }
      })
    }) // end of config changes.

    leavesummariesRef
      .where("domain", "==",this.currentDomain)
      .where("mgremail", "==", this.currentemail)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            //this.teamleavedetails();
            console.log("changed document is", change.doc.data())
            var tmpuid = change.doc.id
            console.log("tmpuid is ", tmpuid)
            var objIndex = this.teamleave.findIndex((obj) => obj.uid == tmpuid)
            console.log("objIndex is ", objIndex)
            if (objIndex > -1) {
              
               this.teamleave[objIndex]=Object.assign({}, change.doc.data())
            }
          }
        })
      }) // end of leave summary manager

    leavedetailsRef
      .where("domain", "==", this.currentDomain)
      .where("email", "==", this.currentemail)
      .onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            //this.leavedetails();
            console.log("changed document is", change.doc.data())
            var tmpuid = change.doc.id
            console.log("tmpuid is ", tmpuid)
            var objIndex = this.leaveall.findIndex((obj) => obj.uid == tmpuid)
            console.log("objIndex is ", objIndex)
            if (objIndex > -1) {
              this.leaveall[objIndex].status = change.doc.data().status
            }
          }
        })
      }) // end of leave deatils

    leavesummariesRef
      .where("domain", "==", this.currentDomain)
      .where("email", "==", this.currentemail)
      .onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            // this.initialize();
            console.log("changed document is", change.doc.data())
            var tmpuid = change.doc.id
            console.log("tmpuid is ", tmpuid)
            var objIndex = this.leave.findIndex((obj) => obj.uid == tmpuid)
            console.log("objIndex is ", objIndex)
            if (objIndex > -1) {
               this.leave[objIndex]=Object.assign({}, change.doc.data())
            }
          }
        })
      }) // end of leave summary self*/

    leavesummariesRef.where("domain", "==", this.currentDomain).onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified" && this.hr) {
          // this.initialize();
          console.log("changed document is", change.doc.data())
          var tmpuid = change.doc.id
          console.log("tmpuid is ", tmpuid)
          var objIndex = this.domainleave.findIndex((obj) => obj.uid == tmpuid)
          console.log("objIndex is ", objIndex)
          if (objIndex > -1) {
            
            this.domainleave[objIndex]=Object.assign({}, change.doc.data())
          }
        }
      })
    }) // end of leave summary hr */

   
    leavedetailsRef.where("domain", "==", this.currentDomain).onSnapshot({ includeMetadataChanges: false }, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        console.log("indie hr change", this.hr, change.type)
        if (change.type === "added" && this.hr) {
          console.log("calling pushcalenderarray functions")
          this.dashboardlistner(change.doc)
        }
      })
    }) // end of calender datails hr */
  }, //end of mounted */

  methods: {
    randomColor() {
      const colorsList = [
        "grey",
        "deep-orange",
        "brown",
        "blue-grey",
        "orange",
        "green",
        "cyan",
        "teal",
        "blue",
        "indigo",
        "pink",
        "red",
        "purple",
      ]
      const rand = Math.floor(Math.random() * colorsList.length)
      return colorsList[rand]
    },
    leaveapporvalpush(doc, editing) {
      this.leaveapporval.push({
        editing: editing,
        ...doc.data(),
         uid: doc.id,
      })
    },

    showholidays(item) {
      this.leaveyear = item
      console.log("inside showholidays ", this.leaveyear)
      var filteredItems = this.holidayslist.filter((holidaylist) => {
        return holidaylist.year == this.leaveyear
      })
      this.Showleave = filteredItems
      this.headers = this.headersholiday
    },
   
    setleave(leaveOption) {
      this.leaveOption=leaveOption;
      this.approval = false
      this.dashboard = false
      this.holidaysoption = false
      this.summaryflag = false
      if (leaveOption == "Summary") {
        this.Showleave = this.leave
        this.headers = this.headersself
        this.summaryflag = true
      } else if (leaveOption == "Detailed") {
        this.Showleave = this.leaveall
        this.headers = this.headersdeatailed
      } else if (leaveOption == "Holidays") {
        this.Showleave = this.holidayslist
        this.headers = this.headersholiday
        this.holidaysoption = true
      } else if (leaveOption == "Team") {
        this.Showleave = this.teamleave
        this.headers = this.teamheader
        this.summaryflag = true
      } else if (leaveOption == "Approval") {
        this.Showleave = this.leaveapporval
        this.headers = this.approvalheader
        this.approval = true
      } else if (leaveOption == "All") {
        this.Showleave = this.domainleave
        this.headers = this.domainheader
        this.summaryflag = true
      } else if (leaveOption == "Dashboard") {
       
        this.Showleave = this.dashboardleaves
        this.headers = this.headersdashboard

        this.dashboard = true
        var tmpdate = this.convertDate(new Date().toISOString().substring(0, 10))
        this.picker = new Date().toISOString().substring(0, 10)
        this.textpicker = this.convertDate(this.picker)
        this.getleavedetails(tmpdate)
      }
    },
    getleavedetails(tmpdate) {
      console.log("inside getleavedetails", tmpdate)
      this.textpicker = this.convertDate(this.picker)
      var filteredItems = this.dashboardleaves.filter((dashboardleave) => {
        return dashboardleave.date == tmpdate
      })
      if (filteredItems.length > 0) {
        this.WFH = filteredItems[0].WFH
        this.EarnedLeave = filteredItems[0].EarnedLeave
        this.Onsite = filteredItems[0].Onsite
        this.Travel = filteredItems[0].Travel
      } else {
        this.WFH = 0
        this.EarnedLeave = 0
        this.Onsite = 0
        this.Travel = 0
      }

    },

    async getconfigs() {
      this.excludingweekends = false
      this.excludingholidays = false
      this.allwoleavecarryforward = false
      this.CFmaxday = 0
      this.leavespermonth = 0

      var querystring = await configgenRef.where("domain", "==", this.domain).get()
      for (var i = 0; i < querystring.docs.length; ++i) {
        var description = querystring.docs[i].data().description
        var value = querystring.docs[i].data().value
        if (description == "Leave excluding Weekend" && value == "YES") this.excludingweekends = true
        else if (description == "Leave excluding Holidays" && value == "YES") this.excludingholidays = true
        else if (description == "Allow leave carryforward" && value == "YES") this.allwoleavecarryforward = true
        else if (description == "Leave carry forward") this.CFmaxdays = Number(value)
        else if (description == "Per Month Leave") this.leavespermonth = Number(value)
      }
    },

    async initialize() {
      this.leave = []

      await this.getconfigs()
      var editing = false

      console.log("configgenRef", this.allwoleavecarryforward)
      if (this.allwoleavecarryforward == "YES") {
        editing = true
      }

      await leavesummariesRef
        .where("domain", "==", this.domain)
        .where("email", "==", this.email)
        .orderBy("year", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.leave.push({
           
              editing: editing,
              ...doc.data(),
              uid: doc.id,
            })
          })
        })
      this.notloaded = false
      this.leavedetails()
      this.hrdomain()
      this.teamapprovals()
      this.teamleavedetails()
      this.holidays()
    },

    async leavedetails() {
      this.leaveall = []
      var editing = false
      editing = false
      await leavedetailsRef
        .where("domain", "==", this.domain)
        .where("email", "==", this.email)
        .orderBy("appliedon", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.leaveall.push({
           
              editing: editing,
              ...doc.data(),
              uid: doc.id,
      
            })
          })
        })
    },

    async holidays() {
    
      this.holidayslist = []
      this.holidayyears = []
      await holidaysRef
        .where("domain", "==", this.domain)
        .orderBy("year", "desc")
        .orderBy("date", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.holidayslist.push({
              uid: doc.id,
              ...doc.data()
            
            })
           /* if (!this.holidayyears.includes(doc.data().year)) {
              this.holidayyears.push(doc.data().year)
              
            }*/
            if(this.holidayyears.indexOf(doc.data().year) <0){
                 this.holidayyears.push(doc.data().year)
                  console.log("holidays", this.holidayyears)
              }
          })
        })
      console.log("leave", this.leave)
      console.log("leaveall", this.leaveall)
      console.log("holidays", this.holidayyears)
    },

    async teamleavedetails() {
      this.teamleave = []
      var editing = true
      var year = new Date().toISOString().substring(0, 4)
      await leavesummariesRef
        .where("domain", "==", this.domain)
        .where("mgremail", "==", this.email)
        .where("year", "==", year)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.teamleave.push({
              editing: editing,
               uid: doc.id,
               ...doc.data()
              
            })
          })
        })
      if (this.teamleave.length > 0) {
        if (this.leaveOptions.includes("Team")) {
          console.log("Team already included")
        } else {
          this.leaveOptions.push("Team")
        }
      }
    },

    async teamapprovals() {
      var editing = false
      this.leaveapporval = []
      editing = true
      await leavedetailsRef
        .where("domain", "==", this.domain)
        .where("mgremail", "==", this.email)
        .where("status", "==", "Applied")
        .orderBy("appliedon", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.leaveapporvalpush(doc, editing)
          })
        })
      if (this.leaveapporval.length > 0) {
        if (this.leaveOptions.includes("Approval")) {
          console.log("Approval alrady included")
        } else {
          this.leaveOptions.push("Approval")
        }
      }
    },

    async hrdomain() {
      var editing = false
      console.log("this.hr", this.hr)
      if (this.hr) {
        this.domainleave = []
        editing = false

        leavesummariesRef
          .where("domain", "==", this.domain)
          .orderBy("year", "desc")
          .orderBy("empid", "asc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.domainleave.push({
                editing: editing,
                 uid: doc.id,
                 ...doc.data()
              })
            })
          })
        this.dashboarddetails()
      }
    },

    async dashboarddetails() {
      var tmpdate = new Date().toISOString().substring(0, 10)
      this.todaysleave = []
      this.leavecalenders = []
      var editing = false
      await leavedetailsRef
        .where("domain", "==", this.domain)
        .where("enddate", ">=", tmpdate)
        .orderBy("enddate", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.pushcalenderarray(doc)
            if (doc.data().startdate <= tmpdate) {
              this.todaysleave.push({
                editing: editing,
                uid: doc.id,
                ...doc.data(),
              })
            }
          })
        })
      if (this.leaveOptions.includes("All")) {
        console.log("ALL already included")
      } else {
        this.leaveOptions.push("All")
        this.leaveOptions.push("Dashboard")
      }
      this.leavecalendersnumbers()
    }, // end of hrdomain;

    async dashboardlistner(doc) {
      await this.pushcalenderarray(doc)
      await this.leavecalendersnumbers()
    },

    async pushcalenderarray(doc) {
      // var i = 0;
      var loopstart = new Date(doc.data().startdate)
      var loopend = new Date(doc.data().enddate)
      while (loopstart <= loopend) {
        // console.log("inside while",loopstart)
        var weekend = false
        var weekday = loopstart.getDay()
        if (weekday == 0 || weekday == 6) {
          weekend = true
        }
        if (!weekend) {
          var color = "red"
          if (doc.data().type == "Earned Leave") color = "red"
          else if (doc.data().type == "Work from Home") color = "yellow"
          else if (doc.data().type == "official Travel") color = "green"
          else if (doc.data().type == "Onsite") color = "green"

          console.log("before push ", loopstart)
          var eventdate = loopstart

          this.leavecalenders.push({
            name: doc.data().name,
            start: new Date(eventdate),
            end: new Date(eventdate),
            color: color,
            empid: doc.data().empid,
            details: doc.data().type,
          })
         
        } // end of not weekend.
        var newDate = loopstart.setDate(loopstart.getDate() + 1)
        loopstart = new Date(newDate)
      } //end of while
    }, // end of pushcalenderarray
    leavecalendersnumbers() {
      var sorteleavecalendersnumbers = []
      var found = false
      var j = 0
      console.log("inside leavecalendersnumbers", this.leavecalenders.length)
      for (var i = 0; i < this.leavecalenders.length; ++i) {
        var tmpdate = this.convertDate(this.leavecalenders[i].start.toISOString().substring(0, 10))
        var tmpdate1 = this.leavecalenders[i].start.toISOString().substring(0, 10)
        console.log("inside for loop", i, tmpdate, this.leavecalenders[i].name, sorteleavecalendersnumbers.length)
        found = false
        j = 0
        while (found == false && j < sorteleavecalendersnumbers.length) {
         
          if (sorteleavecalendersnumbers[j].date == tmpdate) found = true
          else j++
        } //end of while or j
        if (found) {
          var WFH = sorteleavecalendersnumbers[j].WFH
          var EarnedLeave = sorteleavecalendersnumbers[j].EarnedLeave
          var Onsite = sorteleavecalendersnumbers[j].Onsite
          var Travel = sorteleavecalendersnumbers[j].Travel
          if (this.leavecalenders[i].details == "Earned Leave") EarnedLeave++
          else if (this.leavecalenders[i].details == "Onsite") Onsite++
          else if (this.leavecalenders[i].details == "official Travel") Travel++
          else if (this.leavecalenders[i].details == "Work from Home") WFH++
          sorteleavecalendersnumbers[j].WFH = WFH
          sorteleavecalendersnumbers[j].EarnedLeave = EarnedLeave
          sorteleavecalendersnumbers[j].Onsite = Onsite
          sorteleavecalendersnumbers[j].Travel = Travel
        } //end of found
        else {
          //console.log("insdie not found")
          WFH = 0
          EarnedLeave = 0
          Onsite = 0
          Travel = 0
          if (this.leavecalenders[i].details == "Earned Leave") EarnedLeave++
          else if (this.leavecalenders[i].details == "Onsite") Onsite++
          else if (this.leavecalenders[i].details == "official Travel") Travel++
          else if (this.leavecalenders[i].details == "Work from Home") WFH++

          sorteleavecalendersnumbers.push({
            date: tmpdate,
            WFH: WFH,
            EarnedLeave: EarnedLeave,
            Onsite: Onsite,
            Travel: Travel,
            date1: new Date(tmpdate1),
          })
         } //end of new array
      } // end of main for loop
      console.log("sorteleavecalendersnumbers", sorteleavecalendersnumbers)
      this.dashboardleaves = sorteleavecalendersnumbers
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    approveleave(item) {
      this.editedIndex = this.Showleave.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // this.dialog = true
      this.editedItem.status = "Approved"
      this.saveitem()
    },
    rejectleave(item) {
      this.editedIndex = this.Showleave.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // this.dialog = true
      this.editedItem.status = "Not Approved"
      this.saveitem()
    },

    editItem(item) {
      //leavesummariesRef,leavedetailsRef,leaveall,leave
      this.editedIndex = this.Showleave.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.summary = true // to Make new value while ediiting  if it flase then leave details will be opened.
    },

    async deleteItem(item) {
      const index = this.Showleave.indexOf(item)
      const deletemsg = "Are you sure you want to delete this leave?" + item.name
      const option = await this.deleteFromFb(deletemsg)
      console.log("inside deleteItem", option, index, item.uid)
      if (option) {
        try {
          this.leaveall.doc(item.uid).delete()
          this.Showleave.splice(index, 1)
        } catch (error) {
          console.log("Error while delete :", error)
        }
      }
    },

    close() {
      this.dialog = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
     this.spinner = true;
      if (this.editedIndex > -1) {
        Object.assign(this.Showleave[this.editedIndex], this.editedItem)
        console.log("inside saveite update", new Date(), this.editedItem)
        if (this.summary) {
          var CFmaxdays = this.CFmaxdays
          var encashdays = Number(this.editedItem.encash)
          console.log("CFday & CFmaxdays", encashdays, CFmaxdays, parseInt(this.editedItem.closingbalance))
          if (encashdays < 0 || encashdays > CFmaxdays || encashdays > Number(this.editedItem.closingbalance)) {
            this.alertflag = true
            this.alertmsg = "The En-cash days shoudle be between 0 and" + CFmaxdays
          } else {
            try {
              var leavespermonth = this.leavespermonth

              console.log("leavespermonth", leavespermonth)
              await leavesummariesRef.doc(this.editedItem.uid).update({
                encash: encashdays,
                closingbalance: this.editedItem.closingbalance - encashdays,
              })
              var newyear = Number(this.editedItem.year) + 1
              var newopeningbalance = Number(encashdays)
              var newcreditedbalance = 12 * Number(leavespermonth)
              var newclosingbalance = Number(encashdays) + 12 * Number(leavespermonth)

              var newsummaryrecored = {
                name: this.editedItem.name,
                email: this.editedItem.email,
                empid: this.editedItem.empid,
                year: newyear,
                openingbalance: newopeningbalance,
                creditedbalance: newcreditedbalance,
                leavetaken: 0,
                encash: 0,
                domain: this.domain,
                closingbalance: newclosingbalance,
                mgremail: this.editedItem.mgremail,
                status: "Active",
              }
              console.log("new recored for the new year", newsummaryrecored)
              await leavesummariesRef.add(newsummaryrecored)

             
              ;(this.snackbar = true), (this.snackbartext = "leave status updated successfully")
               this.close()
            } catch (error) {
              ;(this.alertflag = true), (this.alertmsg = "Error in  update carry forward")
              console.log("Error while carry forward :", error)
               this.spinner = false
            }
          } // end of allowed encash days.
        } // end of summary if
        else {
          try {
            if (this.editedItem.status == "Approved") {
              await leavedetailsRef.doc(this.editedItem.uid).update({
                status: "Approved",
                approvedby: this.email,
                approvedon: new Date().toISOString().substring(0, 10),
              })
            } else if (this.editedItem.status == "Not Approved") {
              await leavedetailsRef.doc(this.editedItem.uid).update({
                status: "Not Approved",
                approvedby: this.email,
                approvedon: new Date().toISOString().substring(0, 10),
              })

              if (this.editedItem.type == "Earned Leave") {
          
                var year = this.editedItem.year
                days = this.editedItem.days
                var querystring = await leavesummariesRef
                  .where("domain", "==", this.domain)
                  .where("year", "==", year)
                  .where("email", "==", this.editedItem.email)
                  .get()
                for (var i = 0; i < querystring.docs.length; ++i) {
                  var uid = querystring.docs[i].id
                  var leavetaken = querystring.docs[i].data().leavetaken - days
                  var closingbalance = querystring.docs[i].data().closingbalance + days
                  await leavesummariesRef.doc(uid).update({
                    leavetaken: leavetaken,
                    closingbalance: closingbalance,
                  })
                }
              } // end of type leave.
            } // end of Rejected.

            this.mailfun()
            this.close()
            ;(this.snackbar = true), (this.snackbartext = "leave status updated successfully")
          } catch (error) {
            ;(this.alertflag = true), (this.alertmsg = "Error in  update leave")
            console.log("Error while update :", error)
            this.spinner = false
          }
        } //end of sumaary else
      } // end of edited flag true
      else {
        var days = await this.calculateLeaves(
          this.editedItem.startdate,
          this.editedItem.starttime,
          this.editedItem.enddate,
          this.editedItem.endtime
        )
        if (!this.alertflag) {
          var queryresult = await empRef.where("domain", "==", this.domain).where("email", "==", this.email).get()
          if (queryresult.docs[0].data().mgremail.length > 0 && queryresult.docs[0].data().mgrname.length > 0) {
            this.editedItem.mgremail = queryresult.docs[0].data().mgremail
            this.editedItem.mgrname = queryresult.docs[0].data().mgrname
            console.log("after calculateLeaves", new Date(), days)
            this.editedItem.days = days
            this.editedItem.domain = this.domain
            this.editedItem.email = this.email
            this.editedItem.empid = this.empid
            this.editedItem.name = this.name
            this.editedItem.appliedby = this.email
            this.editedItem.appliedon = new Date().toISOString().substring(0, 10)
            this.editedItem.status = "Applied"
            this.editedItem.year = new Date().toISOString().substring(0, 4)

            console.log("inside new item add", new Date(), this.editedItem)
            try {
              await leavedetailsRef
                .add(this.editedItem)
                .then((docRef) => {
                  this.editedItem.uid = docRef.id
                })
                .catch((error) => console.error("Error adding leave: ", error))
              this.leaveall.push(this.editedItem)
              this.mailfun()
              if (this.editedItem.type == "Earned Leave") {
                //only for leave udpate the leave summary else no need .
                this.updatesumarray()
              }
              this.initialize()
              ;(this.snackbar = true), (this.snackbartext = "New leave has been added successfully")
              this.close()
            } catch (error) {
              console.log("error in add new Leave", error)
              this.alertflag = true
              this.alertmsg = "Error in add new Leave"
            }
          } else {
            this.alertflag = true
            this.alertmsg = "unable to get the manager email and manager name"
          }
        } 
      }
    },
    async updatesumarray() {
      var year = this.editedItem.year
      var days = this.editedItem.days
      console.log("inside update summary", year, days)
      var querystring = await leavesummariesRef
        .where("domain", "==", this.domain)
        .where("year", "==", year)
        .where("email", "==", this.email)
        .get()
      console.log("after querystring", querystring)
      for (var i = 0; i < querystring.docs.length; ++i) {
        var uid = querystring.docs[i].id
        var leavetaken = querystring.docs[i].data().leavetaken + days
        var closingbalance = querystring.docs[i].data().closingbalance - days
        console.log("inside loop", querystring.docs[i].data(), leavetaken, closingbalance)

        await leavesummariesRef.doc(uid).update({
          leavetaken: leavetaken,
          closingbalance: closingbalance,
        })
      }
    },
    mailfun() {
      var portalurl = "//localhost:8080/login"
      this.mailmsg.to == this.email + ", " + this.editedItem.mgremail
      this.mailmsg.subject = " leave Update : " + this.name
      this.mailmsg.message = "Hi, leave has been created/updated for " + this.name + ". Please "
      this.mailmsg.message = this.mailmsg.message + '<a href="' + portalurl + '">login</a>'
      this.mailmsg.message = this.mailmsg.message + "to portal and check the details."
      console.log(this.mailmsg)
      //this.sendEmail(this.mailmsg);
    },
    async calculateLeaves(fstartdate, fstarttime, fenddate, fendtime) {
      ///https://www.w3schools.com/js/tryit.asp?filename=tryjs_editor
      var startdate = new Date(fstartdate)
      var enddate = new Date(fenddate)
      var starttime = fstarttime
      var endtime = fendtime
      var loopstart = new Date(startdate)
      var loopend = new Date(enddate)
      var leavedays = 0.0

      console.log("calculateLeaves starts", new Date())
      if (startdate > enddate) {
        ;(this.alertflag = true), (this.alertmsg = "Start date is greater than end date")
        return leavedays
      } else if (startdate.getDay() == 0 || startdate.getDay() == 6) {
        ;(this.alertflag = true), (this.alertmsg = "Start date is falls on holiday")
        return leavedays
      } else if (enddate.getDay() == 0 || enddate.getDay() == 6) {
        ;(this.alertflag = true), (this.alertmsg = "End date is falls on holiday")
        return leavedays
      }
      var tmpstartdate = new Date(startdate).toISOString().substring(0, 10)
      var tmpenddate = new Date(enddate).toISOString().substring(0, 10)

      var querystring = await holidaysRef
        .where("domain", "==", this.domain)
        .where("date", "in", [tmpstartdate, tmpenddate])
        .get()

      if (querystring.docs.length > 0) {
        ;(this.alertflag = true), (this.alertmsg = "Start/end date is falls on holiday")
        return leavedays
      }

      var excludingweekends = this.excludingweekends
      var excludingholidays = this.excludingholidays

      if (starttime == "First session" && endtime == "Second session") {
        leavedays = 0
        loopstart = new Date(startdate)
        loopend = new Date(enddate)
      } else if (starttime == "Second session" && endtime == "Second session") {
        leavedays = 0.5
        loopstart = new Date(startdate.setDate(startdate.getDate() + 1))
        loopend = new Date(enddate)
      } else if (starttime == "Second session" && endtime == "First session") {
        leavedays = 1
        loopstart = new Date(startdate.setDate(startdate.getDate() + 1))
        loopend = new Date(enddate.setDate(enddate.getDate() - 1))
      } else if (starttime == "First session" && endtime == "First session") {
        leavedays = 0.5
        loopstart = new Date(startdate)
        loopend = new Date(startdate.setDate(enddate.getDate() - 1))
      }
      var tmploopstart = new Date(loopstart).toISOString().substring(0, 10)
      var tmploopend = new Date(loopend).toISOString().substring(0, 10)
      var publicholidays = 0
      if (excludingholidays) {
        querystring = await holidaysRef
          .where("domain", "==", this.domain)
          .where("date", ">=", tmploopstart)
          .where("date", "<=", tmploopend)
          .get()

        console.log("tmploopstart", tmploopstart, tmploopend, querystring.docs.length)
        for (var i = 0; i < querystring.docs.length; ++i) {
          var day = querystring.docs[i].data().day
          if (day != "Saturday" && day != "Sunday") publicholidays++
        }
      }

      while (loopstart <= loopend) {
        var weekend = false
        if (excludingweekends) {
          var weekday = loopstart.getDay()
          if (weekday == 0 || weekday == 6) {
            weekend = true
          }
        }
        if (!weekend) {
          leavedays += 1
        }
        var newDate = loopstart.setDate(loopstart.getDate() + 1)
        loopstart = new Date(newDate)
      } //end of while
      leavedays -= publicholidays
      console.log("leavedays ends", new Date(), leavedays)
      return leavedays
    },
  }, // end of methods
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  border: 1px solid black;
}
table.leavesummary {
  table-layout: fixed;
  width: 100%;
}
</style>
