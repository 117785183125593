import axios from "axios"
import { sendmailurl } from "../fb"
//common mixin to sendEmail, like on submission and on signup

export const emailMixin = {
  methods: {
    async sendEmail(body) {
      console.log("inside emailMixin new",sendmailurl)
      try {
        var result = await axios.post(sendmailurl, body)
        //  console.log("getUserRoles:user role fetched sucessfully",userdetails)
        return result
      } catch (err) {
        console.log(err)
        return err
      }
    }, // end of addUser method
  },
}
/*export const emailMixin = {
  methods: {
    async sendEmail(body) {
      //Ret = await axios.post(adduserurl, body)
      console.log("inside emailMixin new",sendmailurl)
      await axios
        .post("https://sendmailsgen-rv6cprgx3a-uc.a.run.app",body)
        //.post("https://us-central1-venzoadmindev.cloudfunctions.net/sendMail", body)
        //.post(sendmailurl,body)
        .then((res) => {
          console.log(res,sendmailurl)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}*/
